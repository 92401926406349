<template>
<section class="solution">
  <div class="container">
    <p>Solution</p>
    <h2>Perfect for every professional in the fashion Industry</h2>
    <div class="row">
      <div class="column">
        <h4 class="color-1"># 01</h4>
        <h3>Manufacturers</h3>
        <p>Streamline production with instant pattern drafting, grading and automated tech packs.</p>
      </div>
      <div class="column">
        <h4 class="color-2"># 02</h4>
        <h3>Fashion Brands</h3>
        <p>From custom pieces to full collections, go to market quickly with creativity and speed.</p>
      </div>
      <div class="column">
        <h4 class="color-3"># 03</h4>
        <h3>Apparel Merchandisers</h3>
        <p>Easily plan and create collections with silhouettes, colorways and printways.</p>
      </div>
      <div class="column">
        <h4 class="color-3"># 04</h4>
        <h3>Clothing Boutiques</h3>
        <p>Sell exclusive designs, unique to your brand  without having a team of designers and product developers.</p>
      </div>
      <div class="column">
        <h4 class="color-1"># 05</h4>
        <h3>Fashion Schools</h3>
        <p>Prepare students for the future with cutting-edge tools for merchandising, pattern design and grading.</p>
      </div>
      <div class="column">
        <h4 class="color-2"># 06</h4>
        <h3>Indie Designers</h3>
        <p>Bring your creative vision to life with professional-grade tools that don't require a large team.</p>
      </div>
    </div>
  </div>
</section>
</template>

<script>
export default {
  name: 'TheSolution',
}
</script>

<style lang="scss">
.home-page {
  .solution {
    .container {
      flex-direction: column;
      padding: 0 80px 160px 80px;

      & > p {
        color: var(--Text-Text_Secondary);
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.18px;
        margin: 0;
        margin-bottom: 15px;
      }

      & > h2 {
        max-width: 620px;
        color: var(--Text-Text_Primary, #1D1F29);
        font-family: Montserrat;
        font-size: 42px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -1.26px;
        margin: 0;
        margin-bottom: 60px;
      }

      .row {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: space-between;
        flex-wrap: wrap;
        row-gap: 60px;

        .column {
          width: 100%;
          max-width: 400px;

          h4 {
            font-family: Montserrat;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.18px;
            margin-bottom: 25px;

            &.color-1 { color: #774C60; }
            &.color-2 { color: #885039; }
            &.color-3 { color: #4C811B; }
          }
          h3 {
            color: var(--Text-Text_Primary);
            font-family: Montserrat;
            font-size: 22px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.22px;
            margin-bottom: 10px;
          }
          p {
            color: #385052;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 160%;
            letter-spacing: -0.48px;
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .home-page {
    .solution {
      .container {
        padding: 80px 20px;

        & > h2 {
          font-size: 24px;
          margin-bottom: 40px;
        }

        .row {
          row-gap: 20px;

          .column {
            p {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
</style>

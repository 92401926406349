<template>
  <div id="app">
    <router-view />
    <ModalComponent :showIcon="false" :show="modalStore.formModal" @close="modalStore.formModal = !modalStore.formModal">
      <template v-slot:title>Join waitlist</template>
      <template v-slot:description>Enter your name and email and we will notify you when the product is released</template>
      <template v-slot:extra>
        <InputField type="text" v-model="modalStore.form.name">
            <template v-slot:top-label>Full name*</template>
          </InputField>
          <InputField type="text" v-model="modalStore.form.email">
            <template v-slot:top-label>Email*</template>
          </InputField>
          <SelectField type="text" v-model="modalStore.form.business" :options="modalStore.business" placeholder="Select">
            <template v-slot:top-label>Business*</template>
          </SelectField>
          <div class="buttons">
            <ButtonSecondary class="small" @click.native="modalStore.formModal = !modalStore.formModal">Cancel</ButtonSecondary>
            <ButtonPrimary
              :disabled="!modalStore.formIsValid"
              class="small"
              @click.native="modalStore.send()"
            >
              Send
            </ButtonPrimary>
          </div>
      </template>
    </ModalComponent>
    <ModalComponent :show="modalStore.formComplete" @close="modalStore.formComplete = !modalStore.formComplete">
      <template v-slot:title>Thank you!</template>
      <template v-slot:description>You request sent</template>
      <template v-slot:extra v-if="modalStore.booking">
        <div class="booking">
          <h3>Do you want to book a demo?</h3>
          <ButtonSecondary class="small" @click.native="modalStore.formComplete = !modalStore.formComplete">No</ButtonSecondary>
          <ButtonPrimary class="small" @click.native="modalStore.toBooking()">Yes</ButtonPrimary>
        </div>
      </template>
    </ModalComponent>
  </div>
</template>

<script>
import ModalComponent from '@/components/Atoms/ModalComponent.vue'
import InputField from '@/components/Atoms/InputField.vue'
import SelectField from '@/components/Atoms/SelectField.vue'
import ButtonPrimary from '@/components/Atoms/ButtonPrimary.vue'
import ButtonSecondary from '@/components/Atoms/ButtonSecondary.vue'
import { mapStores } from 'pinia'
import { useModalStore } from './store/modal.js'

export default {
  name: 'App',
  components: {
    ModalComponent,
    InputField,
    SelectField,
    ButtonPrimary,
    ButtonSecondary,
  },
  computed: {
    ...mapStores(useModalStore),
  },
}
</script>

<style>
  .modal-component-overlay {
    .input-field, .select-field {
      margin-bottom: 16px;
    }

    .buttons {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      column-gap: 16px;
      align-items: center;
      justify-content: space-between;
      padding-top: 8px;
    }

    .booking {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 16px;
      h3 {
        width: 100%;
        text-align: center;
        font-size: 18px;
        margin: 0;
      }
      button {
        width: calc(50% - 8px);
      }
    }
  }
</style>

<template>
  <div class="home-page">
    <TheHeader />
    <TheHero />
    <TheCompatibles />
    <TheWhy />
    <TheFeatures />
    <TheTechnology />
    <TheSolution />
    <TheCaseStudies />
    <SeeInAction />
    <TheFooter />
  </div>
</template>

<script>
import TheHeader from '@/components/Home/TheHeader.vue'
import TheHero from '@/components/Home/TheHero.vue'
import TheCompatibles from '@/components/Home/TheCompatibles.vue'
import TheWhy from '@/components/Home/TheWhy.vue'
import TheFeatures from '@/components/Home/TheFeatures.vue'
import TheTechnology from '@/components/Home/TheTechnology.vue'
import TheSolution from '@/components/Home/TheSolution.vue'
import TheCaseStudies from '@/components/Home/TheCaseStudies.vue'
import SeeInAction from '@/components/Home/SeeInAction.vue'
import TheFooter from '@/components/Home/TheFooter.vue'

export default {
  name: 'HomePage',
  components: {
    TheHeader,
    TheHero,
    TheCompatibles,
    TheWhy,
    TheFeatures,
    TheTechnology,
    TheSolution,
    TheCaseStudies,
    SeeInAction,
    TheFooter,
  },
  beforeMount () {
    if (this.$route.query.params) {
      this.$router.push({
        path: '/designer',
        query: this.$route.query,
      })
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  },
}
</script>

<style lang="scss">
.home-page {
  .container {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    position: relative;
    box-sizing: border-box;
  }
}
</style>

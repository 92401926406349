import VueRouter from 'vue-router'
import Home from '@/pages/Home'
import PrivacyPolicy from '@/pages/PrivacyPolicy'
import TermsAndConditions from '@/pages/TermsAndConditions'

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/privacy-policy',
      name: 'privacy',
      component: PrivacyPolicy,
    },
    {
      path: '/terms-and-conditions',
      name: 'terms',
      component: TermsAndConditions,
    },
  ],
})

export { router }

import { defineStore } from 'pinia'

export const useModalStore = defineStore('modal', {
  state: () => ({
    formModal: false,
    formComplete: false,
    business: [
      { title: 'Fashion Student' },
      { title: 'Indie Designer' },
      { title: 'Small Fashion Brand' },
      { title: 'Medium Size Fashion Brand' },
      { title: 'Large Fashion Brand' },
      { title: 'Large Enterprise' },
      { title: 'Media' },
      { title: 'Other' },
    ],
    bookingOptions: ['Large Fashion Brand', 'Large Enterprise', 'Media'],
    form: {
      name: '',
      email: '',
      business: '',
    },
  }),
  getters: {
    booking () {
      return this.bookingOptions.includes(this.form.business)
    },
    nameIsValid () {
      return this.form.name.length > 0
    },
    emailIsValid () {
      const emailRegExp = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g
      return emailRegExp.test(this.form.email)
    },
    businessIsValid () {
      return this.form.business.length > 0
    },
    formIsValid () {
      return this.nameIsValid && this.emailIsValid && this.businessIsValid
    },
  },
  actions: {
    async send () {
      try {
        await fetch('https://hooks.zapier.com/hooks/catch/3764043/2tzvkt9/', {
          method: 'post',
          body: JSON.stringify({
            name: this.form.name,
            email: this.form.email,
            business: this.form.business,
          }),
        })
        this.formModal = false
        this.formComplete = true
      } catch (e) {
        console.warn(e.message)
      }
    },
    toBooking () {
      window.open('https://calendly.com/yuliya-r/30min', '_blank').focus()
      this.formComplete = false
    },
  },
})

<template>
  <section class="see-in-action">
    <figure>
      <img :src="require('@/assets/home/cta.jpg')" alt="" />
    </figure>
    <div class="container">
      <h3>Join our waitlist</h3>
      <h2>You'll see it in action</h2>
      <p>Be ready to transform your design process?</p>
      <ButtonSecondary @click.native="modalStore.formModal = !modalStore.formModal">Join Waitlist</ButtonSecondary>
    </div>
  </section>
</template>

<script>
import { mapStores } from 'pinia'
import { useModalStore } from '../../store/modal'
import ButtonSecondary from '@/components/Atoms/ButtonSecondary.vue'

export default {
  name: 'SeeInAction',
  components: {
    ButtonSecondary,
  },
  computed: mapStores(useModalStore),
}
</script>

<style lang="scss">
.see-in-action {
  position: relative;
  background-color: rgba(0, 0, 0, 0.5);

  figure {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    margin: 0;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 756px;
  }

  h3 {
    color: var(--Text-Text_White) !important;
    text-align: center;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.18px;
    margin-bottom: 10px;
  }
  h2 {
    color: var(--Text-Text_White) !important;
    text-align: center;
    font-family: Montserrat;
    font-size: 52px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -1.56px;
    margin-bottom: 15px;
  }
  p {
    color: var(--Text-Text_White) !important;
    text-align: center;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.18px;
    margin-bottom: 25px;
  }
}

@media (max-width: 480px) {
  .see-in-action {
    .container {
      display: flex !important;
      padding: 20px;
    }
    h2 {
      font-size: 30px;
    }
  }
}
</style>

<template>
  <footer class="info-pages">
      <div class="container">
        <div class="row">
          <img :src="require('@/assets/home/logo.png')" alt="Patternfast" class="logo" />
        </div>
        <div class="row">
          <h2>From idea to reality, seamlessly</h2>
          <nav>
            <RouterLink :to="{ name: 'privacy' }">Privacy</RouterLink>
            <RouterLink :to="{ name: 'terms' }">Terms and Conditions</RouterLink>
            <div class="button-wrapper">
              <ButtonPrimary class="small" @click.native="modalStore.formModal = !modalStore.formModal">Join Waitlist</ButtonPrimary>
              <a href="https://www.ycombinator.com/launches/LrB-patternfast-ai-for-instantly-manufacturable-fashion" target="_blank">
                <img alt="Launch YC: 🚀 PatternFast: AI for instantly manufacturable fashion" src="https://www.ycombinator.com/launches/LrB-patternfast-ai-for-instantly-manufacturable-fashion/upvote_embed.svg/">
              </a>
            </div>
          </nav>
        </div>
        <div class="row">
          <div class="mailto">
            We're here to help<br>
            <a href="mailto:info@patternfast.com">info@patternfast.com</a>
          </div>
          <span class="copy">&copy; 2024 by PatternFast</span>
        </div>
      </div>
    </footer>
</template>

<script>
import { mapStores } from 'pinia'
import { useModalStore } from '../../store/modal'
import { RouterLink } from 'vue-router'
import ButtonPrimary from '@/components/Atoms/ButtonPrimary.vue'

export default {
  name: 'TheFooter',
  components: {
    RouterLink,
    ButtonPrimary,
  },
  computed: mapStores(useModalStore),
}
</script>

<style lang="scss">
footer.info-pages {
  width: 100%;
  background: var(--Primary-Button-Surface);

  color: var(--Text-Text_Tertairty);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: -0.42px;

  .container {
    flex-direction: column;
    align-items: stretch;
    row-gap: 25px;
    padding: 60px 80px;

    .row {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
    }

    .logo {
      width: 110px;
      height: 52px;
    }

    h2 {
      color: var(--Text-Text_White);
      font-family: Montserrat;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.9px;
      max-width: 300px;
    }

    nav {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 25px;

      a, a:visited {
        color: var(--Background);
        font-family: "Open Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      a:hover {
        text-decoration: underline;
      }

      .button-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;
        img {
          width: auto;
          height: 40px;
          display: block;
        }
      }

      button {
        background: var(--Button-States-Hover);
      }
    }

    .mailto {
      a, a:visited {
        color: var(--Text-Text_White);
        text-decoration: underline;
      }

      a:hover {
        text-decoration: none;
      }
    }

    .copy {
      align-self: flex-end;
    }
  }
}

@media (max-width: 480px) {
  footer.info-pages {
    .container {
      display: flex !important;
      padding: 80px 20px;

      .row {
        flex-direction: column;
        row-gap: 20px;
      }

      h2 {
        font-size: 20px;
      }
      nav {
        flex-wrap: wrap;

        .button-wrapper {
          flex-direction: column;
          align-items: flex-start;

          width: 100%;
          order: -1;
          margin-bottom: 20px;
        }
      }

      .copy {
        align-self: flex-start;
      }
    }
  }
}
</style>

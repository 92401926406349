<template>
  <button class="btn primary">
    <slot name="icon"></slot>
    <slot></slot>
    <svg
      v-if="unfolded !== null"
      :class="[unfolded ? 'unfolded' : '']"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path d="M6.666 7.584a.75.75 0 0 0-.53.219.75.75 0 0 0 0 1.06l3.335 3.334a.75.75 0 0 0 1.06 0l3.332-3.334a.75.75 0 0 0 0-1.06.75.75 0 0 0-1.06 0L10 10.605 7.197 7.803a.75.75 0 0 0-.531-.219z"/>
    </svg>
  </button>
</template>

<script>
export default {
  name: 'buttonPrimary',
  props: {
    unfolded: {
      type: Boolean,
      optional: true,
      default: null,
    },
  },
}
</script>

<style lang="scss">
  .btn.primary {
    height: 48px;
    padding: 0 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
    background: var(--Primary-Shades-Primary);
    border: none;
    border-radius: 8px;

    color: var(--Primary-Button-Text);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    white-space: nowrap;

    transition: all ease-in-out 300ms;

    svg {
      width: 20px;
      height: 20px;
      fill: var(--Primary-Button-Text);
      transform: scaleY(1);
      transition: transfrorm ease-in-out 300ms;

      &.unfolded {
        transform: scaleY(-1);
      }
    }

    &:hover {
      background: var(--Button-States-Hover);
    }

    &:active {
      background: var(--Button-States-Pressed);
    }

    &:disabled {
      background: var(--Button-States-Disabled_Surface);
      color: var(--Button-States-Disabled_Txt);
      opacity: 1 !important;

      svg {
        fill: var(--Button-States-Disabled_Txt);
      }
    }

    &.small {
      height: 40px;
      padding: 0 16px;

      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
</style>

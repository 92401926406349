<template>
  <section class="compatibles">
      <div class="container">
        <span>Compatibility with your tools from:</span>
        <img v-for="(logo, i) in tools" :key="i" :src="logo" alt="" width="167" height="50">
      </div>
    </section>
</template>

<script>
export default {
  name: 'TheCompatibles',
  computed: {
    tools () {
      return [
        require('@/assets/home/tools/optitex.png'),
        require('@/assets/home/tools/bronswear.png'),
        require('@/assets/home/tools/tukatech.png'),
        require('@/assets/home/tools/gerber.png'),
        require('@/assets/home/tools/alvanon.png'),
        require('@/assets/home/tools/clo.png'),
      ]
    },
  },
}
</script>

<style lang="scss">
.home-page {
  .compatibles {
    .container {
      padding:  25px 80px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      border-top: 1px solid var(--Dividers);
      background: var(--Background);

      color: var(--Text-Text_Secondary, #6F8789);
      font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.18px;

      span {
        display: block;
        width: 100%;
        max-width: 180px;
      }
    }
  }
}

@media (max-width: 480px) {
  .home-page {
    .compatibles {
      .container {
        padding:  40px 14px;
        row-gap: 12px;

        span {
          max-width: 100%;
          text-align: center;
          margin-bottom: 12px;
        }
      }
    }
  }
}
</style>

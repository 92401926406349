<template>
  <section class="features">
    <div class="container">
      <p>Features</p>
      <h2>Accelerate your workflow</h2>
      <div class="row r1">
        <div class="text">
          <h4>Get production-ready quickly</h4>
          <h3>Smart pattern adjustments, automatic grading and instant tech packs</h3>
          <p>
            <ul>
              <li>No longer a time-consuming process.</li>
              <li>Automated grading to your brand's fit charts or to our provided standard fit charts.</li>
              <li>Generate tech packs automatically with all necessary specifications, measurements, and design details, just add your ISOs.</li>
            </ul>
          </p>
        </div>
        <div class="image">
          <figure>
            <img :src="require('@/assets/home/feature1.png')" alt="" width="677" height="485" />
          </figure>
        </div>
      </div>
      <div class="row r2">
        <div class="text">
          <h4>Eco-friendly approach</h4>
          <h3>Sustainability in fashion</h3>
          <p>
            Our precision in digital product creation ensures that your patterns fit and are ready for production, minimizing valuable resources, such as time, paper, fabric waste and contributing to more sustainable fashion practices.
          </p>
        </div>
        <div class="image">
          <figure>
            <img :src="require('@/assets/home/feature2.png')" alt="" width="620" height="350" />
          </figure>
        </div>
      </div>
      <div class="row r3">
        <div class="text">
          <h4>Unleash your creativity</h4>
          <h3>Endless design possibilities</h3>
          <p>
            <ul>
              <li>Click and design to instantly experiment with different silhouettes, style lines, lengths, fabrics and details, all while ensuring that your patterns are consistently accurate and production-ready.</li>
              <li>Unlock your new creative fashion potential without the usual technical constraints and quickly release new products.</li>
            </ul>
          </p>
        </div>
        <div class="image">
          <figure>
            <img :src="require('@/assets/home/feature3.png')" alt="" width="620" height="350" />
          </figure>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TheFeatures',
}
</script>

<style lang="scss">
.home-page {
  .features {
    background: var(--Surface-Hover);
    .container {
      flex-direction: column;
      padding: 160px 80px 0 80px;

      & > p {
        color: var(--Text-Text_Secondary);
        text-align: center;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.18px;
        margin: 0;
        margin-bottom: 15px;
      }
      & > h2 {
        color: var(--Text-Text_Primary);
        text-align: center;
        font-family: Montserrat;
        font-size: 52px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -1.56px;
        margin: 0;
        margin-bottom: 60px;
      }
    }

    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 160px;

      .text {
        width: 100%;
        max-width: 475px;

        h4 {
          font-family: Montserrat;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.18px;
          margin-bottom: 10px;
        }

        h3 {
          color: var(--Text-Text_Primary);
          font-family: Montserrat;
          font-size: 30px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.9px;
          margin-bottom: 25px;
        }

        p {
          color: #385052;
          font-family: Montserrat;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 160%; /* 25.6px */
          letter-spacing: -0.48px;
        }

        li {
          list-style: none;
          margin-bottom: 16px;
          padding-left: 36px;
          transform: translateX(-36px);
          background-position: left center;
          background-repeat: no-repeat;
        }

      }

      &.r1 {
        h4 { color: #81976B; }
        li {
          background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHVSURBVHgBpZS9SyNBGMafdzb3keIgcN1Vu/fRHZqFgzvl4GwsDgsVLeyMoLX4F2T/A9MraLCxUBQEGxtBNNqYqKCgibuVWEYQRXDndWb9CpusbuIDy7LvzPvb552ZdwgNVHSLqQRuhgHZxUxpFTJ1nEElQdIjGMs/rd/5RrlUByvv9BnCn1FDKbwsT5BwwmBR+7FX2coZQi7FgGmZkuVs8WTTaehwr1JwiJBFKyLOtVmdE0/A/XIho7zO4A3yJffb3zuX74GnBRcPC9+6uOpfJC0jcEfINJM6v7qBD+/f4XPqU02UPhpJ/1zAoF40oamFNUzOreDYO6sbYym7BHH8UjVsenENY4Pd6Pn3q34CUVowOB2O65J0chRsdKA76p+maBhW50cnP0JjwgIl1OMhtMND///i8uo6AO0eVrB7dBoLpqotJZSbEpjN8OBjsobGgQWFMTw6cLfHmTkXNelE7eYP8wviiNXxI32zGHztxuzfaJhauvavHZawLbvqS4zgrSI4+hXssu5B5TeHFsUMp93qyN9za3Tg7mSZpYNmpIy0ffsz8Ww0pGJ5MyOEyNKrlwUHSxVUVyOKmq7BhupzZmHSczd56myUVNL6bTWZt227Gs67A8A3vDi3ddNdAAAAAElFTkSuQmCC)
        }
      }

      &.r2 {
        h4 { color: var(--Text-Text_Secondary); }
      }

      &.r3 {
        h4 { color: #C48A70; }
        li {
          background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHOSURBVHgBpVQ9SFtRGD3nvliztNGlQ4cSx1YpEQoplEKXuqadOjYdnEVw1rg/MLOLurkZMyaLIGicDBIFQTQoiFuMiz+Q9/ndF4nhJdEXPXDfe/eee8/77vdHdEFtd3fIDN7+o+CnEAkI4k2GZUKqMMy9+5Rc6XaWHWJ7O7+N4y0pNYSnQFRJZoLCpn1Sr5SyxpG1Z8Us1GrxZLm+v53pKugTxBT6BueuDkoLrZl91CrbaUMu4RXwGvwz/CWZ8wXrB6WTR8e/FHLp3UVHHGsdwXQ/RxcLm4gODOB97G3bKqOMNC6MQ5NCH3BzBcyu5lE5O+/gRNPMCMJf1Yq5+SJmUhP4+/1rB6/+S2iUJREk7JXcXLGn2EzqV69/xk23VRspN19oiYYU8xHRUUXg2pMTP1C/voa7XsDW4ZGO41BiinJEI1wWSDzIWAHfuvViWDHNPC1HzcEp/cr22lQ5PcfYxw8IAwrT9DvLm5uTUPX7NKqx0W8jZnh8/NJrmP94JdS6jH37UbY1CDKLF0Pb2FizjbXSJvY5OW0J9AthNjaanG9JB/nannYeh3N4toLEd5V/u3Zbe223wnSodc44H6pJmjlbJrHh3Q6uWP8Hz90DCGusUg87f2QAAAAASUVORK5CYII=)
        }
      }

      .image {
        figure {
          width: fit-content;
          height: fit-content;
          min-width: 620px;
          min-height: 350px;
          border-radius: 20px;
          overflow: hidden;
          margin: 0;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .home-page {
    .features {
      .container {
        padding: 80px 20px;

        & > h2 {
          font-size: 30px;
          margin-bottom: 40px;
        }
      }

      .row {
        flex-direction: column;
        margin-bottom: 80px;

        &:last-child {
          margin-bottom: 0;
        }

        .text {
          h4 {
            padding-left: 36px;
          }
          h3 {
            padding-left: 36px;
            font-size: 20px;
          }

          p {
            padding-left: 36px;
          }

        }

        .image {
          width: 100%;

          figure {
            aspect-ratio: 62 / 35;
            min-width: 100%;
            min-height: auto;

            &:first-child {
              overflow: visible;
            }

            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }
  }
}
</style>

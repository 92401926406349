<template>
  <Transition name="modal">
    <div class="modal-component-overlay" v-if="show" @click.self="$emit('close')">
      <div :class="`modal-component ${closeButton ? '' : 'no-button'}`">
        <button class="close-modal" @click="$emit('close')" v-if="closeButton">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 25">
            <path d="M6 5.75a.75.75 0 0 0-.531.219.75.75 0 0 0 0 1.062l5.468 5.469-5.468 5.469a.75.75 0 0 0 0 1.062.75.75 0 0 0 1.062 0L12 13.563l5.469 5.468a.75.75 0 0 0 1.062 0 .75.75 0 0 0 0-1.062L13.063 12.5l5.468-5.469a.75.75 0 0 0 0-1.062A.75.75 0 0 0 18 5.75a.75.75 0 0 0-.531.219L12 11.437 6.531 5.97A.75.75 0 0 0 6 5.75z"/>
          </svg>
        </button>
        <svg v-if="type === 'loader'" class="loader" xmlns="http://www.w3.org/2000/svg" width="42" height="42" fill="none" viewBox="0 0 42 42">
          <g stroke="#09373D" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" clip-path="url(#a)">
            <path d="M21 10.5V5.25M10.5 21H5.25m8.312-7.437L9.8 9.8"/>
          </g>
          <defs>
            <clipPath id="a">
              <path fill="#fff" d="M0 0h42v42H0z"/>
            </clipPath>
          </defs>
        </svg>
        <div v-if="type !== 'loader' && showIcon" :class="['icon', type]">
          <slot name="icon">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32" v-if="type === 'error'">
              <path d="M16 2.668C8.645 2.668 2.666 8.645 2.666 16S8.646 29.334 16 29.334c7.355 0 13.334-5.98 13.334-13.334 0-7.355-5.98-13.332-13.334-13.332zm0 1.5A11.821 11.821 0 0 1 27.834 16c0 6.544-5.29 11.834-11.834 11.834A11.823 11.823 0 0 1 4.166 16C4.166 9.456 9.456 4.168 16 4.168zm0 4.416a.75.75 0 0 0-.75.75v7.998a.75.75 0 0 0 .75.75.75.75 0 0 0 .75-.75V9.334a.75.75 0 0 0-.75-.75zm0 11.998a.75.75 0 0 0-.75.75v.002a.75.75 0 0 0 .75.75.75.75 0 0 0 .75-.75v-.002a.75.75 0 0 0-.75-.75z"/>
            </svg>
            <template v-if="type === 'success'">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 33" v-if="iconName === 'outline'">
                <path fill="#000" d="M16 3.752c-.765 0-1.53.263-2.146.789l-1.63 1.387c-.29.247-.65.397-1.03.427l-2.132.17a3.32 3.32 0 0 0-3.037 3.037l-.17 2.131c-.03.38-.18.742-.427 1.032L4.04 14.354a3.316 3.316 0 0 0 0 4.292l1.387 1.63c.247.29.397.65.427 1.03l.17 2.131a3.32 3.32 0 0 0 3.037 3.038l2.131.17c.38.03.742.18 1.032.427l1.629 1.387a3.317 3.317 0 0 0 4.292 0l1.63-1.387c.29-.247.65-.397 1.03-.427l2.131-.17a3.32 3.32 0 0 0 3.038-3.038l.17-2.13c.03-.38.18-.742.427-1.032l1.387-1.629a3.317 3.317 0 0 0 0-4.292l-1.387-1.63a1.814 1.814 0 0 1-.427-1.03l-.17-2.132a3.32 3.32 0 0 0-3.038-3.037l-2.13-.17c-.38-.03-.742-.18-1.032-.427L18.146 4.54A3.303 3.303 0 0 0 16 3.752zm0 1.496c.417 0 .833.144 1.174.434l1.629 1.388c.53.452 1.188.724 1.883.78l2.132.17c.892.07 1.591.77 1.662 1.662l.17 2.132c.056.695.328 1.353.78 1.883l1.388 1.63a1.8 1.8 0 0 1 0 2.347l-1.388 1.629a3.307 3.307 0 0 0-.78 1.883l-.17 2.132a1.805 1.805 0 0 1-1.662 1.662l-2.132.17a3.307 3.307 0 0 0-1.883.78l-1.63 1.388a1.8 1.8 0 0 1-2.347 0l-1.629-1.388a3.307 3.307 0 0 0-1.883-.78l-2.132-.17a1.805 1.805 0 0 1-1.662-1.662l-.17-2.132a3.306 3.306 0 0 0-.78-1.883l-1.388-1.63a1.8 1.8 0 0 1 0-2.347l1.388-1.629c.452-.53.724-1.188.78-1.883l.17-2.132A1.805 1.805 0 0 1 9.182 8.02l2.132-.17a3.306 3.306 0 0 0 1.883-.78l1.63-1.388c.34-.29.756-.434 1.173-.434zm4 7.834a.75.75 0 0 0-.531.22l-4.803 4.803-2.135-2.136a.75.75 0 0 0-1.062 0 .75.75 0 0 0 0 1.06l2.668 2.668a.75.75 0 0 0 1.06 0l5.334-5.334a.75.75 0 0 0 0-1.06.75.75 0 0 0-.531-.221z"/>
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 33" v-if="iconName === 'check'">
                <path fill="#09373d" d="M13.7 22.9c-.2 0-.4-.1-.5-.2L7.5 17c-.3-.3-.3-.8 0-1.1s.8-.3 1.1 0l5.1 5.1 10.8-10.8c.3-.3.8-.3 1.1 0s.3.8 0 1.1L14.2 22.7c-.2.1-.4.2-.5.2z"/>
              </svg>
            </template>
          </slot>
        </div>
        <div class="text-holder" v-if="$slots.title || $slots.description">
          <h3 v-if="$slots.title"><slot name="title" /></h3>
          <p v-if="$slots.description"><slot name="description" /></p>
        </div>
        <div class="extra" v-if="$slots.extra"><slot name="extra" /></div>
        <div class="buttons" v-if="$slots.buttons"><slot name="buttons" /></div>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  name: 'ModalComponent',
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'success',
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    iconName: {
      type: String,
      default: 'outline',
    },
    closeButton: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="scss">
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.modal-component-overlay {
  padding: 16px;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background: rgba(29, 31, 41, 0.50);

  &.modal-enter-active,
  &.modal-leave-active {
    transition: all ease-in-out 300ms;
    background: rgba(29, 31, 41, 0.50);

    .modal-component {
      transition: all ease-in-out 300ms;
      transform: scale(1);
      opacity: 1;
    }
  }

  &.modal-enter,
  &.modal-leave-to {
    background: rgba(29, 31, 41, 0);

    .modal-component {
      transform: scale(0.9);
      opacity: 0;
    }
  }

  .modal-component.no-button {
    padding: 48px 16px;
  }
  .modal-component {
    width: 422px;
    height: auto;
    display: flex;
    padding: 16px 16px 48px 16px;
    flex-direction: column;
    align-items: center;
    row-gap: 32px;

    border-radius: 12px;
    background: var(--white);

    .close-modal {
      background: transparent;
      border: none;
      align-self: end;

      svg {
        display: block;
        width: 24px;
        height: 24px;
        fill: var(--secondary);
      }
    }

    .loader {
      animation: spin 1.5s linear infinite;
    }

    .icon {
      width: 64px;
      height: 64px;
      border-radius: 64px;
      box-sizing: border-box;
      padding: 16px;

      svg {
        display: block;
        width: 100%;
        height: 100%;
      }

      &.success {
        background: var(--Dividers);

        svg {
          fill: var(--Icons-Icon_Primary);
        }
      }

      &.error {
        background: var(--Active-States-Surfaces);
        svg {
          fill: var(--System-Error);
        }
      }

    }

    .text-holder, .extra {
      width: 100%;
      max-width: 300px;
    }
    .text-holder {
      text-align: center;
    }

    h3 {
      color: var(--Text-Text_Primary);

      font-family: Montserrat;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 130%;
      margin-bottom: 8px;
    }
    p {
      color: var(--Text-Text_Secondary);

      font-family: "Open Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      margin-bottom: 0;
    }

    .buttons {
      width: 100%;
      max-width: 300px;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: nowrap;
      justify-content: stretch;
      column-gap: 16px;

      & > * {
        width: 100%;
      }
      .one {
        width: 202px;
      }
    }
    .buttons:has(.one) {
      justify-content: center;
    }
  }
}
@media (max-width: 480px) {
  .modal-component-overlay .modal-component .buttons .one {
    width: 103px;
  }
}

</style>

<template>
  <div class="privacy">
    <TheHeader :scrollY="0" />
    <section>
      <div class="container">
        <h2>PaatternFast (patternfast.com)</h2>
        <h1>Privacy Policy</h1>
        <p>Type of webService: SaaS (Software as a Service)<br>Effective date: 2nd day of September, 2024</p>
        <p>Patternfast.com (the "Service") is owned and operated by Mylinego, Inc (PatternFast). Mylinego, Inc (PatternFast) is the data controller and can be contacted at:</p>
        <p>
          info@patternfast.com<br>
          (888) 883-3840<br>
          1900 South Norfolk Street Suite 350, Office 357 San Mateo, CA 94403
        </p>
        <h3>Purpose</h3>
        <p>The purpose of this privacy policy (this "Privacy Policy") is to inform users of our Service of the following:</p>
        <ol>
          <li>The personal data we will collect;</li>
          <li>Use of collected data;</li>
          <li>Who has access to the data collected;</li>
          <li>The rights of Service users; and</li>
          <li>The Service's cookie policy.</li>
        </ol>
        <p>This Privacy Policy applies in addition to the terms and conditions of our Service.</p>
        <h3>GDPR</h3>
        <p>For users in the European Union, we adhere to the Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016, known as the General Data Protection Regulation (the "GDPR"). For users in the United Kingdom, we adhere to the GDPR as enshrined in the Data Protection Act 2018.</p>
        <p>We have not appointed a Data Protection Officer as we do not fall within the categories of controllers and processors required to appoint a Data Protection Officer under Article 37 of the GDPR.</p>
        <h3>Consent</h3>
        <p>By using our Service users agree that they consent to:</p>
        <ol>
          <li>The conditions set out in this Privacy Policy.</li>
        </ol>
        <h3>Legal Basis for Processing</h3>
        <p>We collect and process personal data about users in the EU only when we have a legal basis for doing so under Article 6 of the GDPR.</p>
        <p>We rely on the following legal bases to collect and process the personal data of users in the EU:</p>
        <ol>
          <li>Processing of user personal data is necessary for us or a third party to pursue a legitimate interest. Our legitimate interest is not overridden by the interests or fundamental rights and freedoms of users. Our legitimate interest(s) are processing content and data uploaded by users to the System.</li>
          <li>Processing of user personal data is necessary for us to take, at the request of a user, steps before entering a contract or for the performance of a contract to which a user is a party. If a user does not provide the personal data necessary to perform a contract the consequences are as follows: PatternFast is unable to deliver services, draft personalized, made-to-measure apparel patterns if no personal measurements are provided..</li>
        </ol>
        <h3>Personal Data We Collect</h3>
        <p>We only collect data that helps us achieve the purpose set out in this Privacy Policy. We will not collect any additional data beyond the data listed below without notifying you first.</p>
        <p>Please note that you are not legally required to provide personal information to any Mylinego, Inc. employees or team members. However, without providing personal information, you might not be able to receive answers to your inquiries, purchase a subscription, use the Service, or receive related services from the Mylinego, Inc. that operates in your territory. By using the Service or receiving information or services related to it, you agree to and authorize the Mylinego, Inc. you engaged with (or any other Mylinego, Inc. you interacted with) to transfer your personal data to other Mylinego, Inc. entities and their sub-processors.</p>
        <h3>Data Collected Automatically</h3>
        <p>When you visit and use our Service, we may automatically collect and store the following information:</p>
        <ol>
          <li>IP address;</li>
          <li>Location;</li>
          <li>Hardware and software details;</li>
          <li>Clicked links and content viewed.</li>
        </ol>
        <h3>Data Collected in a Non-Automatic Way</h3>
        <p>We may also collect the following data when you perform certain functions on our Service:</p>
        <ol>
          <li>First and last name;</li>
          <li>Age;</li>
          <li>Date of birth;</li>
          <li>Gender;</li>
          <li>Email address;</li>
          <li>Phone number;</li>
          <li>Address;</li>
          <li>Payment information;</li>
          <li>Auto fill data;</li>
          <li>Body measurements, height, weight;</li>
          <li>Physical properties, eye color, hair color, skin color.</li>
        </ol>
        <p>This data may be collected using the following methods:</p>
        <ol>
          <li>WebService forms.</li>
        </ol>
        <h3>How We Use Personal Data</h3>
        <p>Data collected on our Service will only be used for the purposes specified in this Privacy Policy or indicated on the relevant pages of our Service. We will not use your data beyond what we disclose in this Privacy Policy.</p>
        <p>The data we collect automatically is used for the following purposes:</p>
        <ol>
          <li>Statistics, data processing.</li>
        </ol>
        <p>The data we collect when the user performs certain functions may be used for the following purposes:</p>
        <ol>
          <li>Communication, account set up and maintenance.</li>
        </ol>
        <h3>Who We Share Personal Data With</h3>
        <p>Employees</p>
        <p>We may disclose user data to any member of our organization who reasonably needs access to user data to achieve the purposes set out in this Privacy Policy.</p>
        <h3>Other Disclosures</h3>
        <p>We will not sell or share your data with other third parties, except in the following cases:</p>
        <ol>
          <li>If the law requires it;</li>
          <li>If it is required for any legal proceeding;</li>
          <li>To prove or protect our legal rights and to investors;</li>
          <li>Buyers or potential buyers of this company in the event that we seek to sell the company.</li>
        </ol>
        <p>If you follow hyperlinks from our Service to another Service, please note that we are not responsible for and have no control over their privacy policies and practices.</p>
        <h3>How Long We Store Personal Data</h3>
        <p>User data will be stored until the purpose the data was collected for has been achieved. You will be notified if your data is kept for longer than this period.</p>
        <h3>How We Protect Your Personal Data</h3>
        <p>In order to protect your security, we use the strongest available browser encryption and store all of our data on servers in secure facilities. All data is only accessible to our employees. Our employees are bound by strict confidentiality agreements and a breach of this agreement would result in the employee's termination.</p>
        <p>While we take all reasonable precautions to ensure that user data is secure and that users are protected, there always remains the risk of harm. The Internet as a whole can be insecure at times and therefore, we are unable to guarantee the security of user data beyond what is reasonably practical.</p>
        <h3>International Data Transfers</h3>
        <p>We transfer user personal data to the following countries:</p>
        <ol>
          <li>We process and store information in the U.S. and other countries. By using the Services, you authorize Mylinego, Inc (PatternFast) to transfer your personal information across national borders;</li>
          <li>All countries where Mylinego, Inc (PatternFast) and its partners operate.</li>
        </ol>
        <p>When we transfer user personal data we will protect that data as described in this Privacy Policy and comply with applicable legal requirements for transferring personal data internationally.</p>
        <p>If you are located in the United Kingdom or the European Union, we will only transfer your personal data if:</p>
        <ol>
          <li>The country your personal data is being transferred to has been deemed to have adequate data protection by the European Commission or, if you are in the United Kingdom, by the United Kingdom adequacy regulations;</li>
          <li>We have implemented appropriate safeguards in respect of the transfer. For example, the recipient is a party to binding corporate rules, or we have entered into standard EU or United Kingdom data protection contractual clauses with the recipient..</li>
        </ol>
        <h3>Your Rights as a User</h3>
        <p>Under the GDPR, you have the following rights:</p>
        <ol>
          <li>Right to be informed;</li>
          <li>Right of access;</li>
          <li>Right to rectification;</li>
          <li>Right to erasure;</li>
          <li>Right to restrict processing;</li>
          <li>Right to data portability;</li>
          <li>Right to object.</li>
        </ol>
        <h3>Children</h3>
        <p>The minimum age to use our webService is 16 years of age. We do not knowingly collect or use personal data from children under 16 years of age. If we learn that we have collected personal data from a child under 16 years of age, the personal data will be deleted as soon as possible. If a child under 16 years of age has provided us with personal data their parent or guardian may contact our privacy officer.</p>
        <h3>How to Access, Modify, Delete, or Challenge the Data Collected</h3>
        <p>If you would like to know if we have collected your personal data, how we have used your personal data, if we have disclosed your personal data and to who we disclosed your personal data, if you would like your data to be deleted or modified in any way, or if you would like to exercise any of your other rights under the GDPR, please contact our privacy officer here privacy@patternfast.com</p>
        <h3>Do Not Track Notice</h3>
        <p>Do Not Track ("DNT") is a privacy preference that you can set in certain web browsers. We do not track the users of our Service over time and across third party webServices and therefore do not respond to browser-initiated DNT signals.</p>
        <h3>Cookie Policy</h3>
        <p>A cookie is a small file, stored on a user's hard drive by a webService. Its purpose is to collect data relating to the user's browsing habits. You can choose to be notified each time a cookie is transmitted. You can also choose to disable cookies entirely in your internet browser, but this may decrease the quality of your user experience.</p>
        <p>We use the following types of cookies on our Service:</p>
        <ol>
          <li>
            <strong>Functional cookies</strong><br>
            Functional cookies are used to remember the selections you make on our Service so that your selections are saved for your next visits;
          </li>
          <li>
            <strong>Analytical cookies</strong><br>
            Analytical cookies allow us to improve the design and functionality of our Service by collecting data on how you access our Service, for example data on the content you access, how long you stay on our Service, etc;
          </li>
          <li>
            <strong>Targeting cookies</strong><br>
            Targeting cookies collect data on how you use the Service and your preferences. This allows us to personalize the information you see on our Service for you;
          </li>
          <li>
            <strong>Third-Party Cookies</strong><br>
            Third-party cookies are created by a web Service other than ours. We may use third-party cookies to achieve the following purposes:
          </li>
        </ol>
        <h3>Additional Clauses</h3>
        <p>License. Subject to your compliance with the Terms and applicable laws, we hereby grant you a non- exclusive, limited, non-transferable, non-sublicensable, revocable right for you to install, access and use the Services that we make available to you, and that you license from us.</p>
        <h3>Modifications</h3>
        <p>This Privacy Policy may be amended from time to time in order to maintain compliance with the law and to reflect any changes to our data collection process. When we amend this Privacy Policy we will update the "Effective Date" at the top of this Privacy Policy. We recommend that our users periodically review our Privacy Policy to ensure that they are notified of any updates. If necessary, we may notify users by email of changes to this Privacy Policy.</p>
        <h3>Complaints</h3>
        <p>If you have any complaints about how we process your personal data, please contact us through the contact methods listed in the Contact Information section so that we can, where possible, resolve the issue. If you feel we have not addressed your concern in a satisfactory manner you may contact a supervisory authority. You also have the right to directly make a complaint to a supervisory authority. You can lodge a complaint with a supervisory authority by contacting the information Commissioner's Office in the UK, Data Protection Commission in Ireland.</p>
        <h3>Contact Information</h3>
        <p>If you have any questions, concerns or complaints, you can contact our privacy team at privacy@patternfast.com</p>
      </div>
    </section>
    <TheFooter />
  </div>
</template>

<script>
import TheHeader from '@/components/Home/TheHeader.vue'
import TheFooter from '@/components/Home/TheFooter.vue'

export default {
  name: 'PrivacyPolicy',
  components: {
    TheHeader,
    TheFooter,
  },
  mounted () {
    window.scrollTo(0, 0)
  },
}
</script>

<style lang="scss">
.privacy {
  .container {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    position: relative;
    box-sizing: border-box;
    flex-direction: column;
    align-items: baseline;
    padding: 132px 80px 154px 80px;

    & > h2 {
      color: var(--Text-Text_Secondary);
      width: 100%;
      text-align: center;
      font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.18px;
      margin: 0;
      margin-bottom: 10px;
    }

    & > h1 {
      color: var(--Text-Text_Primary);
      width: 100%;
      text-align: center;
      font-family: Montserrat;
      font-size: 50px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -1.5px;
      margin: 0;
      margin-bottom: 25px;
    }

    & > h3 {
      color: var(--Text-Text_Primary);
      font-family: Montserrat;
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      margin: 0;
      margin-bottom: 5px;
      margin-top: 16px;
    }

    & > p {
      width: 100%;
      margin: 0;
      margin-bottom: 8px;
    }

    & > ol {
      padding-left: 24px;
      margin-bottom: 8px;

      li {
        padding-left: 16px;
      }
    }
  }
}
@media (max-width: 480px) {
  .privacy  {
    .container {
      padding: 122px 20px 63px 20px;

      & > h1 {
        font-size: 30px;
      }
      & > h3 {
        font-size: 18px;
      }
    }
  }
}
</style>

<template>
  <section class="technology">
    <div class="container">
      <p>PatternFast's Fashion 3.0 Advanced Proprietary Technology</p>
      <h2>Limitless, Instantly Manufacturable Design&nbsp;Possibilities</h2>
      <figure>
        <img :src="require('@/assets/home/technology.jpg')" alt="" />
      </figure>
      <div class="row">
        <div class="column">
          <img :src="require('@/assets/home/tech-icon-1.png')" alt="" width="50" height="50">
          <h4>AI-powered designer</h4>
          <p>Explore new creative directions with designs tailored to your style.</p>
        </div>
        <div class="column">
          <img :src="require('@/assets/home/tech-icon-2.png')" alt="" width="50" height="50">
          <h4>Collaborative team features</h4>
          <p>Share designs, collaborate in real-time, and streamline your entire workflow, all in one place.</p>
        </div>
        <div class="column">
          <img :src="require('@/assets/home/tech-icon-3.png')" alt="" width="50" height="50">
          <h4>Customizable fit charts</h4>
          <p>Ensure your designs fit perfectly across all sizes, from bespoke MTM garments to mass production.</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TheTechnology',
}
</script>

<style lang="scss">
.home-page {
  .technology {
    border-top: 1px solid var(--Dividers);
    .container {
      flex-direction: column;
      padding: 160px 80px;
      background: #FFF;

      & > p {
        color: var(--Text-Text_Secondary, #6F8789);
        text-align: center;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.18px;
        margin: 0;
        margin-bottom: 15px;
      }
      & > h2 {
        color: var(--Text-Text_Primary, #1D1F29);
        text-align: center;
        font-family: Montserrat;
        font-size: 50px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -1.5px;
        margin: 0;
        margin-bottom: 60px;
      }

      figure {
        aspect-ratio: 1280 / 575;
        max-width: 1280px;
        width: 100%;
        height: auto;
        margin: 0 auto;
        border-radius: 20px;
        margin-bottom: 60px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .row {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: nowrap;

        .column {
          width: 100%;
          max-width: 400px;

          img {
            width: 50px;
            height: 50px;
            margin-bottom: 25px;
          }
          h4 {
            color: var(--Text-Text_Primary);
            font-family: Montserrat;
            font-size: 22px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.22px;
            margin-bottom: 10px;
          }
          p {
            color: #385052;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 160%; /* 25.6px */
            letter-spacing: -0.48px;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .home-page {
    .technology {
      .container {
        padding: 80px 20px 64px 20px;

        & > h2 {
          font-size: 30px;
          margin-bottom: 40px;
        }

        figure {
          margin-bottom: 40px;
        }

        .row {
          flex-direction: column;

          .column {
            p {
              margin-bottom: 16px;
            }
          }
        }
      }
    }
  }
}
</style>

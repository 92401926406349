<template>
  <section class="hero">
    <figure>
      <img :src="require('@/assets/home/hero.jpg')" alt="" />
    </figure>
    <div class="container">
      <div class="top-block">
        <p>Create collections in minutes, not&nbsp;months</p>
        <h1>AI for Instantly Manufacturable Apparel Design and&nbsp;Development</h1>
        <div class="buttons">
          <ButtonSecondary @click.native="modalStore.formModal = !modalStore.formModal">Join Waitlist</ButtonSecondary>
          <a href="https://www.ycombinator.com/launches/LrB-patternfast-ai-for-instantly-manufacturable-fashion" target="_blank">
            <img alt="Launch YC: 🚀 PatternFast: AI for instantly manufacturable fashion" src="https://www.ycombinator.com/launches/LrB-patternfast-ai-for-instantly-manufacturable-fashion/upvote_embed.svg/">
          </a>
        </div>
      </div>
      <div class="bottom-block">
        Whether you are a large fashion brand or an Indie designer, our platform lets you create 100+ billion of customizable, digital production-ready MTM and standard sized blocks in a few clicks.
      </div>
    </div>
  </section>
</template>

<script>
import { mapStores } from 'pinia'
import { useModalStore } from '../../store/modal'
import ButtonSecondary from '@/components/Atoms/ButtonSecondary.vue'

export default {
  name: 'TheHero',
  computed: mapStores(useModalStore),
  components: {
    ButtonSecondary,
  },
}
</script>

<style lang="scss">
.home-page {
  .hero {
    position: relative;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%);

    figure {
      position: absolute;
      width: 100%;
      height: 100%;
      margin: 0;
      z-index: -1;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .buttons {
      display: flex;
      align-items: center;
      gap: 16px;

      img {
        display: block;
        height: 48px;
        width: auto;
      }
    }

    .container {
      position: relative;
      z-index: 2;
      padding:  132px 80px 80px 80px;
      height: 812px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .top-block {
      width: 100%;
      max-width: 730px;

      p {
        color: var(--Text-Text_White);
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.18px;
        margin: 0;
        margin-bottom: 10px;
      }

      h1 {
        color: var(--Text-Text_White);
        font-family: Montserrat;
        font-size: 50px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -1.5px;
        margin: 0;
        margin-bottom: 25px;

      }
    }

    .bottom-block {
      width: 100%;
      max-width: 400px;
      align-self: flex-end;

      color: var(--Basic-White);
      font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 160%;
      letter-spacing: -0.54px;
    }
  }
}

@media (max-width: 480px) {
  .home-page {
    .hero {
      figure {
        img {
          object-position: 62%;
        }
      }
      .container {
        padding:  40px 20px;
        align-items: flex-end;
      }

      .top-block {
        h1 {
          font-size: 30px;
          letter-spacing: -0.9px;
        }
      }

      .buttons {
        flex-direction: column;
        align-items: flex-start;
      }

      .bottom-block {
        display: none;
      }
    }
  }
}
</style>

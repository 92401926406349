<template>
  <section class="why">
    <div class="container">
      <p>Why PatternFast</p>
      <h2>Adapts to any collection,<br>big or small</h2>
      <div class="columns">
        <div class="column">
          <figure>
            <img :src="require('@/assets/home/why1.jpg')" alt="" />
          </figure>
          <h3>Accurate fit</h3>
          <p>Ensure fit precision in every pattern block with smart adjustments, shrinkage and compression. The fit of our blocks was tested on 350k people.</p>
        </div>
        <div class="column">
          <figure>
            <img :src="require('@/assets/home/why2.jpg')" alt="" />
          </figure>
          <h3>Fast pattern block generation</h3>
          <p>Bring your collections to life faster with PatternFast's rapid instantly manufacturable design ideation.</p>
        </div>
        <div class="column">
          <figure>
            <img :src="require('@/assets/home/why3.jpg')" alt="" />
          </figure>
          <h3>Bespoke solutions</h3>
          <p>From mass production to custom designs, PatternFast is capable to create unique solutions for large enterprise companies.</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TheWhy',
}
</script>

<style lang="scss">
.home-page {
  .why {
    background: var(--primary);
    border-top: 1px solid var(--Dividers);
    .container {
      flex-direction: column;
      padding:  160px 80px;

      & > p {
        color: var(--Text-Text_Secondary);
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.18px;
        margin: 0;
        margin-bottom: 15px;
      }

      & > h2 {
        color: var(--Text-Text_White);
        font-family: Montserrat;
        font-size: 42px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -1.26px;
        margin: 0;
        margin-bottom: 60px;
      }
    }

    .columns {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: space-between;
      flex-wrap: nowrap;

      .column {
        width: 100%;
        max-width: 400px;

        figure {
          aspect-ratio: 20 / 13;
          width: 100%;
          height: auto;
          border-radius: 20px;
          overflow: hidden;
          margin: 0;
          margin-bottom: 20px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        h3 {
          color: var(--Text-Text_White);
          font-family: Montserrat;
          font-size: 22px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.22px;
          margin: 0;
          padding-left: 8px;
          margin-bottom: 12px;
        }

        p {
          color: var(--Text-Text_Tertairty);
          font-family: Montserrat;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 160%;
          letter-spacing: -0.48px;
          margin: 0;
          padding-left: 8px;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .home-page {
    .why {
      .container {
        padding:  80px 20px;

        & > h2 {
          font-size: 24px;
          margin-bottom: 40px;
        }
      }

      .columns {
        flex-direction: column;
        row-gap: 40px;

        .column {
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
}
</style>

<template>
  <header :class="['info-pages', headerScrolled ? 'scrolled' : '']">
    <div class="container">
      <div class="logo" @click="logoNav()">
        <img :src="require('@/assets/home/logo.png')" alt="Patternfast" class="white" />
        <img :src="require('@/assets/home/logo-color.png')" alt="Patternfast" class="color" />
      </div>
      <nav>
        <ButtonPrimary class="small" @click.native="modalStore.formModal = !modalStore.formModal">Join Waitlist</ButtonPrimary>
      </nav>
    </div>
  </header>
</template>

<script>
import { mapStores } from 'pinia'
import { useModalStore } from '../../store/modal'
import ButtonPrimary from '@/components/Atoms/ButtonPrimary.vue'

export default {
  name: 'TheHeader',
  components: {
    ButtonPrimary,
  },
  computed: mapStores(useModalStore),
  props: {
    scrollY: {
      type: Number,
      default: 800,
    },
  },
  data: () => ({
    headerScrolled: false,
  }),
  methods: {
    logoNav () {
      if (this.$route.name !== 'home') this.$router.push({ name: 'home' })
    },
    scroll () {
      this.headerScrolled = window.scrollY >= this.scrollY
    },
  },
  created () {
    window.addEventListener('scroll', this.scroll)
    this.scroll()
  },
  unmounted () {
    window.removeEventListener('scroll', this.scroll)
  },
}
</script>

<style lang="scss">
header.info-pages {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;
  transition: all 300ms ease-in-out;
  background-color: transparent;
  border-bottom: 1px solid transparent;

  &.scrolled {
    background-color: var(--Background);
    border-bottom: 1px solid var(--Dividers);

    img.white {
      opacity: 0 !important;
    }
    img.color {
      opacity: 1 !important;
    }

    nav a {
      color: var(--Primary-Button-Surface) !important;
    }
  }

  .container {
    padding: 10px 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .logo {
    position: relative;
    width: 110px;
    height: 52px;
    cursor: pointer;

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      transition: opacity 300ms ease-in-out;

      &.white {
        opacity: 1;
      }
      &.color {
        opacity: 0;
      }
    }
  }

  nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 25px;

    a, a:visited {
      color: var(--Basic-White);
      font-family: "Open Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      transition: color 300ms ease-in-out;
    }
  }
}

@media (max-width: 480px) {
  header.info-pages {
    .container {
      padding: 20px 20px 10px 20px;
    }
    nav {
      column-gap: 15px;
    }
  }
}
</style>

<template>
  <div class="terms">
    <TheHeader :scrollY="0" />
    <section>
      <div class="container">
        <h2>PatternFast by Mylinego, Inc.</h2>
        <h1>Business Terms</h1>
        <p>1. Formation and Eligibility</p>
        <p>1.1. These terms ("Terms") outline the rules for using PatternFast, a service offered by Mylinego, Inc ("Services").</p>
        <p>1.2. A contract for the Services ("Contract") is established when you complete and submit an online registration form ("Registration") and click "accept" or a similar option, or when we send you a confirmation email or deliver the Services (including any preliminary work), whichever comes first.</p>
        <p>1.3. Any additional terms or conditions, including those proposed by you, will not apply unless we explicitly agree to them in writing.</p>
        <p>1.4. The Contract is between you, as detailed in the Registration, and Mylinego, Inc., located at 1900 South Norfolk Street, Suite 350, San Mateo, CA 94403, with tax ID 462555212 ("Mylinego, Inc (PatternFast)", "we", "us", "our").</p>
        <p>2. Duration and Termination</p>
        <p>2.1. We will provide the Services to you as per this Contract once you complete the Registration.</p>
        <p>2.2. You will be billed for the Services upon Registration ("Payment Date") using the credit card details you provided or via bank slip issuance.</p>
        <p>2.3. For monthly plans, the Contract will automatically renew each month on the Payment Date (or the nearest business day) using the payment method you provided. For annual plans, the Contract will renew automatically on the anniversary of the Payment Date.</p>
        <p>2.4. You can cancel the Contract at any time by selecting "Cancel" on your profile page. Cancellation will take effect on the next Payment Date. Upon cancellation: (a) no refunds will be issued for amounts already paid; and (b) if you chose installment payments, any remaining installments will become immediately due.</p>
        <p>2.5. Neither party may terminate, suspend, or modify the Contract except as outlined in these Terms.</p>
        <p>2.6. Either party may terminate the Contract immediately with written notice if the other party breaches the Contract significantly or repeatedly and fails to correct the breach within 10 days of receiving notice, if the breach is correctable.</p>
        <p>2.7. Either party may terminate the Contract immediately if the other party experiences an Insolvency Event.</p>
        <p>2.8. We may terminate the Contract or suspend the Services (in whole or in part) if we reasonably believe you have breached these Terms. The suspension will remain until you rectify the breach.</p>
        <p>3. Payment Terms</p>
        <p>3.1. Payments to Mylinego, Inc. (PatternFast) must be made as detailed in the Registration.</p>
        <p>3.2. After the first year following the Payment Date, we may increase the fee annually at our discretion.</p>
        <p>3.3. If available, the option to pay for the Annual Plan in installments does not constitute a monthly fee for accessing the Services.</p>
        <p>4. Warranties</p>
        <p>4.1. We will provide the Services with reasonable skill and care.</p>
        <p>4.2. We make no assurances regarding the reliability, availability, timeliness, suitability, accuracy, or completeness of the Services or the results you might achieve.</p>
        <p>4.3. We do not guarantee that:</p>
        <p>a) The Services will operate without interruptions or errors;</p>
        <p>b) The Services will meet your specific needs;</p>
        <p>c) The Services will work properly with third-party services, technologies, hardware, or software.</p>
        <p>4.4. You acknowledge that the Services may have limitations and issues inherent to electronic communications.</p>
        <p>4.5. The Services are provided "as is," and it is your responsibility to ensure they meet your requirements.</p>
        <p>4.6. You agree not to rely solely on the Services for making decisions.</p>
        <p>4.7. We may:</p>
        <p>a) Temporarily suspend the Services without notice for emergency repairs or improvements;</p>
        <p>b) Suspend the Services with notice for scheduled maintenance;</p>
        <p>c) Suspend the Services without notice if we believe you have breached the Contract.</p>
        <p>4.8. We may modify or discontinue any Services or features at any time without liability, provided there is no significant detriment to their operation.</p>
        <p>4.9. Except as expressly permitted, you must not disclose, resell, or make the Services available to third parties without our written permission. You also agree not to:</p>
        <p>i) Reverse engineer or decompile the Services;</p>
        <p>ii) Use the Services to develop competing products or services;</p>
        <p>iii) Remove proprietary notices or use trademarks without authorization;</p>
        <p>iv) Use the Services in a manner that disrupts or damages our business;</p>
        <p>v) Store or transmit harmful code;</p>
        <p>vi) Interfere with the integrity or performance of the Services;</p>
        <p>vii) Frame or mirror content from the Services, except for internal use;</p>
        <p>viii) Attempt unauthorized access to the Services or related systems;</p>
        <p>ix) Circumvent any restrictions or limitations in the Contract.</p>
        <p>5. Intellectual Property</p>
        <p>5.1. All intellectual property rights in the Services remain with us or our licensors and are not transferred to you.</p>
        <p>5.2. We grant you a non-transferable, non-exclusive, revocable, worldwide, royalty-free license to use the Services for your internal purposes during the term of the Contract.</p>
        <p>5.3. Any rights not explicitly granted are reserved by us. You assign any rights you may acquire in our intellectual property to us.</p>
        <p>5.4. You retain ownership of all intellectual property rights in the Customer Materials.</p>
        <p>5.5. We may use information obtained in connection with the Services to enhance our offerings.</p>
        <p>5.6. We will indemnify you against losses from claims that the Services infringe a third party's intellectual property rights, except where:</p>
        <p>i) The infringement results from modifications made at your direction;</p>
        <p>ii) You do not use updated versions provided by us;</p>
        <p>iii) The Services are modified by you or a third party without our consent;</p>
        <p>iv) The Services are combined with other systems not covered by the Contract.</p>
        <p>5.7. You must:</p>
        <p>a) Notify us promptly of any infringement claims;</p>
        <p>b) Allow us control over the defense and settlement of such claims;</p>
        <p>c) Assist us with reasonable support regarding the claims.</p>
        <p>5.8. If a claim impacts your use of the Services, we may:</p>
        <p>a) Obtain the right for you to continue using the Services;</p>
        <p>b) Replace or modify the Services to avoid infringement;</p>
        <p>c) Terminate the Services and refund any pre-payment.</p>
        <p>5.9. You will indemnify us against losses from claims that Customer Materials infringe third-party intellectual property rights. We must:</p>
        <p>a) Notify you of the claim promptly;</p>
        <p>b) Allow you control over the defense and settlement;</p>
        <p>c) Provide reasonable assistance with the claim.</p>
        <p>6. Delivery and Performance</p>
        <p>6.1. You must provide us with the necessary information, instructions, and materials to fulfill our obligations under the Contract.</p>
        <p>6.2. If you cause any delays or failures in our performance, we will not be liable for any resulting losses.</p>
        <p>7. Insurance</p>
        <p>7.1. Both parties must maintain adequate insurance to cover potential liabilities under the Contract, including any legally required insurance, and must use reputable insurers.</p>
        <p>8. Compliance with Laws</p>
        <p>8.1. Both parties must adhere to all applicable laws, including those related to data privacy and protection.</p>
        <p>9. Consequences of Termination</p>
        <p>9.1. Termination of the Contract will not affect any other existing Contracts between the parties.</p>
        <p>9.2. Provisions intended to survive termination will remain in effect.</p>
        <p>10. Liability</p>
        <p>10.1. Nothing in the Contract limits liability for:</p>
        <p>a) Death or personal injury caused by negligence;</p>
        <p>b) Fraudulent misrepresentation;</p>
        <p>c) Any liability that cannot be excluded by law;</p>
        <p>d) Breach of usage restrictions in these Terms.</p>
        <p>10.2. Subject to the above:</p>
        <p>a) Neither party will be liable for indirect, consequential, special, or other damages, including lost profits or data loss, except for agreed Service fees;</p>
        <p>b) Except for indemnity claims related to intellectual property, liability will be limited to twice the amount paid under the Contract;</p>
        <p>c) Neither party is liable for failures or delays caused by events beyond its reasonable control.</p>
        <p>10.3. You cannot use these liability limitations to avoid paying amounts owed to us.</p>
        <p>10.4. Each party acknowledges that it has not relied on any representations not explicitly included in the Contract.</p>
        <p>11. Confidentiality and Data Protection</p>
        <p>11.1. Both parties will:</p>
        <p>a) Keep Confidential Information confidential and not disclose it to third parties;</p>
        <p>b) Use Confidential Information only in relation to the Contract, unless otherwise permitted by these Terms.</p>
        <p>11.2. Confidential Information does not include information that is:</p>
        <p>a) Publicly available or becomes public through no fault of the receiving party;</p>
        <p>b) Known to the receiving party before disclosure or received from a third party without restrictions;</p>
        <p>c) Independently developed by the receiving party.</p>
        <p>11.3. Confidential Information may be disclosed:</p>
        <p>a) To affiliates, agents, contractors, and suppliers under similar non-disclosure terms;</p>
        <p>b) As required by law, with prompt notice to the disclosing party.</p>
        <p>11.4. Confidential Information remains the property of the disclosing party and must be returned or destroyed upon request, except as required for legal obligations.</p>
        <p>11.5. Both parties must comply with applicable data protection laws and take appropriate measures to protect personal data.</p>
        <p>12. General Terms</p>
        <p>12.1. The Contract benefits only the parties and their successors. It does not grant third-party rights.</p>
        <p>12.2. The Contract constitutes the entire agreement and supersedes all previous agreements.</p>
        <p>12.3. In case of conflict, documents will be prioritized in the following order: Registration, Terms, and any referenced documents.</p>
        <p>12.4. You warrant that the person signing has the authority to bind you and will ensure that employees or agents comply with the Contract.</p>
      </div>
    </section>
    <TheFooter />
  </div>
</template>

<script>
import TheHeader from '@/components/Home/TheHeader.vue'
import TheFooter from '@/components/Home/TheFooter.vue'

export default {
  name: 'TermsAndConditions',
  components: {
    TheHeader,
    TheFooter,
  },
  mounted () {
    window.scrollTo(0, 0)
  },
}
</script>

<style lang="scss">
.terms {
  .container {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    position: relative;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    padding: 132px 80px 154px 80px;

    & > h2 {
      color: var(--Text-Text_Secondary);
      text-align: center;
      font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.18px;
      margin: 0;
      margin-bottom: 10px;
    }

    & > h1 {
      color: var(--Text-Text_Primary);
      text-align: center;
      font-family: Montserrat;
      font-size: 50px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -1.5px;
      margin: 0;
      margin-bottom: 25px;
    }

    & > p {
      width: 100%;
      margin: 0;
      margin-bottom: 8px;
    }
  }
}
@media (max-width: 480px) {
  .terms  {
    .container {
      padding: 122px 20px 63px 20px;

      & > h1 {
        font-size: 30px;
      }
    }
  }
}
</style>
